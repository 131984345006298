<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="authentication/user">
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open(0)">-->
      <!--        {{$l("common.add", "新增")}}-->
      <!--      </el-button>-->
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.phone','手机号')">
            <el-input v-model.trim="filter.phone" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('user.name','姓名')">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <!-- <vm-search :label="$l('user.type','用户类型')">
            <vm-dict-select v-model.trim="filter.type" type="userType"></vm-dict-select>
          </vm-search> -->
          <vm-search :label="$l('user.status','用户状态')">
            <vm-dict-select v-model.trim="filter.status" type="userStatus"></vm-dict-select>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="phone" :label="$l('user.phone','手机号')" align="center"></el-table-column>
      <el-table-column prop="name" :label="$l('user.name','姓名')" align="center"></el-table-column>
      <el-table-column prop="roleName" label="用户角色" align="center">
        <template slot-scope="scope">
          {{scope.row.roleTypes instanceof Array ? scope.row.roleTypes.map(item=>{
            if (item.roleName){
              return item.roleName.replace('[','')

            }else {
              return ''
            }
          }):''}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="typeDesc" :label="$l('user.type','用户类型')" align="center"></el-table-column> -->
      <el-table-column prop="statusDesc" :label="$l('user.status','用户状态')" align="center">
        <template slot-scope="scope">
          <el-switch
            v-if="$auth('用户管理禁用')"
            :value="scope.row.status==='enabled'"
            active-text="启用"
            inactive-text="禁用"
            @change="toggleStatus(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <!--          <el-button type="primary" v-if="$auth('用户管理编辑')" @click="$refs.editPage.open(scope.row.id,scope.row.userType)">-->
          <!--            {{$l("common.edit", "编辑")}}-->
          <!--          </el-button>-->
          <!--          <el-button type="danger" v-if="$auth('用户管理删除')" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>-->
          <el-button v-if="$auth('用户管理重置密码')" type="warning" @click="resetPassword(scope.row)">{{$l("user.resetPassword", "重置密码")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
  import EditPage from "./UserEdit.vue";
  import loginUtil from "@/util/loginUtil";

  export default {
    components: {EditPage},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",
          companyId: loginUtil.getCompanyCode(),
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      toggleStatus(row) {
        let statusDesc = row.status === "enabled" ? "禁用" : "启用";
        this.$confirm(`确定${statusDesc + row.phone}吗?`, "提示", {type: "warning"}).then(() => {
          this.$http.put(`authentication/user/${row.id}/status-toggle`).then(() => {
            this.getList(-1);
          });
        });
      },
      resetPassword(row) {
        this.$prompt("请输入新密码", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: this.$rule.password.pattern,
          inputErrorMessage: "6~20位,不能为纯数字或者纯字母",
        }).then(({value}) => {
          this.$http.put(`authentication/user/${row.id}/password-reset?newPassword=${value}`).then(() => {
            this.$message.success("重置密码成功");
          });
        });
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.phone}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("authentication/user", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.phone + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
